<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn small class="px-1" @click="grantAccess" v-on="on">{{ $t('message.grantAccess') }}</v-btn>
        </template>
        <span>{{ $t('message.grantAppicAccess') }}</span>
    </v-tooltip>
</template>

<script>
import {api} from "Api";
import srs from "secure-random-string";

export default {
    name: "GrantAppicAccessV2",
    props: ['contactName','contactEmail','customerId'],
    data() {
        return {
            loading: {
                get: false,
                grant: false
            },
            minPasswordLength: 8
        }
    },
    methods: {
        async grantAccess() {
            if(await this.$root.$confirm(this.$t('message.grantAppicAccessTo') + ' ' +  this.contactName + ' (' + this.contactEmail + ')', this.$t('message.confirmations.continueGrantAppicAccessAction'), {width: '400', color: 'orange'})){
                const newPassword = srs({length: this.minPasswordLength, alphanumeric: true})
                const contact = {
                    customerId: this.customerId,
                    email: this.contactEmail,
                    name: this.contactName,
                    password: newPassword
                }
                let promise = new Promise ((resolve, reject) => {
                    api
                        .post("/contacts/appic-access", {
                            contact: contact
                        })
                        .then(response => {
                            if (response.data.status == 'success') {
                                resolve('done')
                            } else {
                                resolve(response.data.message)
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
                this.loading.grant = true
                promise
                    .then(response => {
                        if(response == 'done'){
                            this.$toast.success(this.$t('message.successes.appicAccessGranted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.grant = false
                            this.$emit('access-granted')
                            this.editDialog = false
                        } else {
                            this.$toast.error(response,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.$emit('access-not-granted')
                            this.loading.grant = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.appicAccessNotGranted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.$emit('access-not-granted')
                        this.loading.grant = false
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>